<template>
  <a-modal
    wrap-class-name="password-modal"
    :visible="visible"
    :mask-closable="false"
    :confirm-loading="loading"
    @ok="handleSubmit"
    @cancel="$emit('update:visible', false)"
  >
    <template #title>
      <lock-filled class="ic-lock" />
      <span>{{ $t('common_text_034') }}</span>
    </template>
    <div class="password-input">
      <a-input-password v-model:value="password" autocomplete="new-password" focus :placeholder="$t('common_text_035')" />
    </div>
    <div v-if="errorTip" class="error-tip">{{ errorTip }}</div>
  </a-modal>
</template>
<script>
import { reactive, toRefs } from 'vue'
import i18n from '@/locale'
import md5 from 'js-md5'
import { _pay } from '@/api'
export default {
  name: 'PasswordModal',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup(props, ctx) {
    const state = reactive({
      loading: false,
      password: '',
      errorTip: ''
    })

    const handleSubmit = async () => {
      if (!state.password) {
        state.errorTip = i18n.global.t('common_text_036')
      } else {
        state.errorTip = ''
        state.loading = true
        const { email } = JSON.parse(localStorage.getItem('userInfo'))
        const passwdMap = [{ key: 0, value: md5(email + state.password) }]
        const res = await _pay.checkPayPassword(passwdMap)
        if (res.data.succ) {
          state.password = ''
          state.errorTip = ''
          ctx.emit('update:visible', false)
          ctx.emit('pass', res.data.data)
        } else {
          state.errorTip = res.data.resMsg
        }
        state.loading = false
      }
    }

    return {
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.password-modal {
  .ic-lock {
    color: @main-color;
    margin-right: 5px;
  }
  .error-tip {
    margin-top: 8px;
    color: @error-color;
  }
}
</style>
