<template>
  <a-modal
    wrap-class-name="verify-modal"
    :visible="visible"
    :mask-closable="false"
    :confirm-loading="loading"
    :title="$t('common_text_061')"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <div class="code-input">
      <div style="margin-bottom: 12px">{{ $t('common_text_062', [bindInfo.areaCode, bindInfo.formatMobile]) }}</div>
      <div class="flex-align">
        <a-input
          v-model:value="verificationCode"
          allow-clear
          focus
          :placeholder="$t('common_text_006') + $t('pages_user_020')"
        />
        <a-button
          type="primary"
          style="margin-left: 24px"
          :disabled="countNum > 0"
          :loading="codeLoading"
          @click="sendCode"
        >{{ countNum > 0 ? `${countNum}${$t('pages_user_018')}` : $t('pages_user_017') }}</a-button>
      </div>
    </div>
    <div v-if="errorTip" class="error-tip">{{ errorTip }}</div>
  </a-modal>
</template>
<script>
import { reactive, toRefs, onUnmounted } from 'vue'
import { message } from 'ant-design-vue'
import { _user } from '@/api'
import i18n from '@/locale'
export default {
  name: 'PasswordModal',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    bindInfo: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup(props, ctx) {
    const state = reactive({
      loading: false,
      timer: null,
      countNum: 0,
      codeLoading: false,
      verificationCode: '',
      errorTip: ''
    })

    onUnmounted(() => {
      clearInterval(state.timer)
    })

    const sendCode = async () => {
      state.codeLoading = true
      const res = await _user.transactionTriggeredSms()
      state.codeLoading = false
      if (res.data.succ) {
        state.countNum = 60
        state.timer = setInterval(() => {
          state.countNum--
          if (state.countNum === 0) clearInterval(state.timer)
        }, 1000)
      } else {
        message.error(res.data.resMsg)
      }
    }

    const handleCancel = () => {
      state.countNum = 0
      clearInterval(state.timer)
      ctx.emit('update:visible', false)
    }

    const handleSubmit = async () => {
      if (!state.verificationCode) {
        state.errorTip = i18n.global.t('pages_user_020') + i18n.global.t('common_text_042')
      } else {
        state.errorTip = ''
        state.loading = true
        const res = await _user.verificationCodeCheck({
          phone: props.bindInfo.mobile,
          code: state.verificationCode
        })
        if (res.data.succ) {
          state.verificationCode = ''
          state.errorTip = ''
          handleCancel()
          ctx.emit('pass', res.data.data)
        } else {
          state.errorTip = res.data.resMsg
        }
        state.loading = false
      }
    }

    return {
      sendCode,
      handleCancel,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.verify-modal {
  .ic-lock {
    color: @main-color;
    margin-right: 5px;
  }
  .error-tip {
    margin-top: 8px;
    color: @error-color;
  }
}
</style>
